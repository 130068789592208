@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New:wght@300;400;500;700;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,opsz,wght@0,6..96,400..900;1,6..96,400..900&display=swap");

/*  font-family: "Bodoni Moda", serif;
font-family: 'Quicksand';
font-family: 'Zen Kaku Gothic'
 */

body {
   background-color: #d1d0bf;
   padding: 0px;
   margin: 0px;

}

.header {
   display: flex;
   justify-content: center;
   background-color: #cfe0d2;
}

.main-container {
   background-color: #f9f5eb;
   display: flex;
   flex-direction: column;
   margin: 4rem 5rem 1rem 5rem;
   border-radius: 10px;
   align-items: center;
   font-family: "Bodoni Moda", serif;
   font-size: 32px;
}

.subheader {
   font-size: 28px;
   font-family: "Bodoni Moda", serif;
   margin-top: -2rem;
   color: #000000c2;
   text-align: center;
}

.card {
   background-color: #f9f5eb;
   margin-bottom: 50px;
   width: 60%;
   border-radius: 25px;
   font-size: 26px;
   font-family: "Bodoni Moda", serif;
   max-width: 50%;
}

.card p {
   font-family: "Quicksand";
   font-size: 16px;
   text-align: center;
   margin: 0 auto;
}

address {
   padding-top: 2rem;
   font-style: normal;
}

img {
   max-width: 25%;
   border-radius: 1rem;
}

h1,
h2,
h3 {
   text-align: center;
   color: #000000c2;
}

h1 {}

.card-text-container {
   display: flex;
   justify-content: center;
   margin: 0rem 5rem 0rem;
   padding-bottom: 2rem;
   /* width här? */
}

.line {
   width: 100%;
   height: 3px;
   background: linear-gradient(to right,
         rgba(151, 162, 100, 0) 0%,
         rgba(151, 162, 100, 255) 50%,
         rgba(151, 162, 100, 0) 100%);
   flex-shrink: 0;
}

address {
   text-align: center;
}

ul {
   list-style-position: inside;
   text-align: left;
   padding-left: 25%
}


@media (max-width: 768px) {
   .main-container {
      margin: 2rem 1rem;
      font-size: 24px;
   }

   .subheader {
      font-size: 20px;
      margin-top: 0;
   }

   .card {
      width: 90%;
      font-size: 18px;
      margin-bottom: 20px;
      max-width: 100%;
   }

   .card-text-container {
      margin: 0 1rem;
      padding-bottom: 1rem;
   }

   h1 {
      font-size: 28px;
   }

   h2 {
      font-size: 24px;
   }
}